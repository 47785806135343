import { useStateContext } from "../../context/ContextProvider";

const Hook = ({
    hookImage,
    hookContent
}) => {
    const {
        t 
    } = useStateContext();
    return (
        <div 
            className="relative my-5 py-5 min-h-[300px] flex items-center justify-center text-center text-white"
            style={{
                textShadow: "1px 1px 3px #1B4720, -1px -1px 3px #A8D07D",
                background: `linear-gradient(to right bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url(${hookImage}`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <h1 className="w-[70%] py-5 text-2xl font-bold leading-8 lg:leading-10">
                {hookContent}
            </h1>
        </div>
    );
}

export default Hook;