import React from "react";
import Logo from "../../assets/logo-2x.png";
import { useStateContext } from "../../context/ContextProvider";

import {
    BiGlobe,
    BiLogoFacebook,
    BiLogoInstagram,
    BiLogoLinkedin,
    BiLogoTiktok,
    BiLogoYoutube
} from "react-icons/bi";

import {
    BsTelephone,
    BsEnvelopeAt,
    BsPinMap
} from "react-icons/bs";
import CustomButton from "../elements/CustomButton";


const Footer = () => {
    const {
        t
    } = useStateContext();

    const isMobile = window.innerWidth <= 800;

    const handleFormSubmit = async () => {
        const name = document.getElementById('name').value;
        const contact = document.getElementById('contact').value;
        const message = document.getElementById('message').value;

        const formData = new FormData();
        formData.append('name', name);
        formData.append('contact', contact);
        formData.append('message', message);

        // console.log("CONTACT SUBMIT CLICKED == ", formData);
        // for (let [key, value] of formData.entries()) {
        //     console.log(`${key}: ${value}`);
        // }

        try {
            const response = await fetch('../../controllers/sendEmail.php', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                console.log('Email sent successfully!');
                // Add your success handling logic here
            } else {
                console.error('Failed to send email');
                // Add your error handling logic here
            }
        } catch (error) {
            console.error('Error occurred:', error);
            // Add your error handling logic here
        }
    };

    return (
        <div
            className="bg-[#184720] relative text-center text-white w-full pt-5"
            style={{
                zIndex: 4,
            }}
        >
            {/* LOGO AND LINKS */}
            <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-3 pt-5 px-5">
                {/* LOGO  */}
                <div
                    className="col-span-1 flex flex-col items-center p-5"
                    style={{
                        justifyContent: "flex-start",
                        alignContent: "space-between"
                    }}
                >
                    <img src={Logo} className="h-24 w-auto mb-2" alt="Hikal Tech" />
                    <p className="text-sm my-2">
                        {t("brand_subtitle")}
                    </p>
                    {/* <div className="h-0.5 w-full my-2 bg-[#a8D07D]"></div> */}
                    <div className="h-0.5 w-full my-2 bg-[#a8D07D]"></div>
                    {/* CONTACT LINKS */}
                    <div className="justify-content-start text-left mt-2">
                        <div className="grid grid-cols-7 py-1">
                            <BsTelephone size={20} className="me-5 mt-1" />
                            <p className="col-span-6">
                                <span>+20 102 545 2404</span>
                                <span className="mx-2">|</span>
                                <span>+20 100 777 4778</span>
                                <span className="mx-2">|</span>
                                <span>+20 120 458 0777</span>
                            </p>
                        </div>
                        <div className="grid grid-cols-7 py-1">
                            <BsEnvelopeAt size={20} className="me-5 mt-1" />
                            <p className="col-span-6">info@lycheegardens.com</p>
                        </div>
                        <div className="grid grid-cols-7 py-1">
                            <BsPinMap size={20} className="me-5 mt-1" />
                            <p className="col-span-6">
                                Arab Engineering Association, Farm No 50A, Belbais Road, Obour City, Cairo, Egypt
                            </p>
                        </div>
                    </div>
                    {/* END - CONTACT LINKS */}
                </div>

                {/* MAP  */}
                <div
                    className="col-span-1 flex flex-col items-center p-5 text-[#CCCCCC]"
                    style={{
                        justifyContent: "flex-start",
                        alignContent: "space-between",
                    }}
                >
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d27560.40282003582!2d31.46199302461962!3d30.292628144931374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDE4JzExLjkiTiAzMcKwMjcnNTEuNCJF!5e0!3m2!1sen!2sae!4v1708326545554!5m2!1sen!2sae" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d27560.40282003582!2d31.46199302461962!3d30.292628144931374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDE4JzExLjkiTiAzMcKwMjcnNTEuNCJF!5e0!3m2!1sen!2sae!4v1708326545554!5m2!1sen!2sae"
                        width="300"
                        height="300"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        style={{
                            border: 0,
                        }}
                    ></iframe>
                </div>

                {/* DETAILS  */}
                <div
                    className="col-span-1 flex flex-col items-center p-5 text-[#CCCCCC]"
                    style={{
                        justifyContent: "flex-start",
                        alignContent: "space-between",
                    }}
                >
                    <h6 className="font-bold text-white uppercase">{t("contact")}</h6>
                    <hr className="mt-1 mb-2 w-[70%]" />

                    <div className="w-full p-4 text-start flex flex-col gap-4">
                        <input id="name" type="text" className="w-full" placeholder={t("name")} required />
                        <input id="contact" type="text" className="w-full" placeholder={t("contact_number")} required />
                        <textarea id="message" className="w-full" placeholder={t("message")} required />

                        {/* <button
                            // onClick={handleFormSubmit}
                            className="w-full uppercase p-2 rounded-xl"
                        >
                            {t("submit")}
                        </button> */}

                        <CustomButton
                            onClick={handleFormSubmit}
                            buttonText={t("submit")}
                            buttonColor={"secondary"}
                            borderColor={"white"}
                        />
                    </div>
                </div>
            </div>

            {/* COPYRIGHT  */}
            <hr className="mt-1 mb-2 w-full" />
            <div className={`flex items-center px-7 gap-1 ${isMobile ? "flex-col justify-center pb-4" : "flex-row justify-between pb-2"}`}>
                <div className="text-center text-sm p-2 uppercase">
                    All Rights Reserved © {new Date().getFullYear()}
                    <a
                        href="https://hikaltech.com"
                        className="font-semibold px-2 text-[var(--secondary-color)]"
                    >
                        Hikal Tech
                    </a>
                </div>

                {/* SOCIAL ICONS  */}
                <div className="grid grid-cols-6 gap-4 place-content-center items-center">
                    <a href="https://lycheegardens.com" className="w-full flex" style={{ justifyContent: "space-evenly" }} target="_blank">
                        <BiGlobe size={22} />
                    </a>
                    <a href="#" className="w-full flex" style={{ justifyContent: "space-evenly" }} target="_blank">
                        <BiLogoLinkedin size={22} />
                    </a>
                    <a href="#" className="w-full flex" style={{ justifyContent: "space-evenly" }} target="_blank">
                        <BiLogoFacebook size={22} />
                    </a>
                    <a href="https://www.instagram.com/lycheegardens.eg" className="w-full flex" style={{ justifyContent: "space-evenly" }} target="_blank">
                        <BiLogoInstagram size={22} />
                    </a>
                    <a href="https://www.tiktok.com/@lycheegardens" className="w-full flex" style={{ justifyContent: "space-evenly" }} target="_blank">
                        <BiLogoTiktok size={22} />
                    </a>
                    <a href="https://www.youtube.com/channel/UCjf_Vq6BDW9XipFHIq5Wfbg" className="w-full flex" style={{ justifyContent: "space-evenly" }} target="_blank">
                        <BiLogoYoutube size={22} />
                    </a>
                </div>
                {/* END - SOCIAL ICONS  */}
            </div>
        </div>
    );
}

export default Footer;