const CustomButton = ({
    buttonText,
    buttonColor,
    borderColor,
    onClick
}) => {
    return (
        <>
            <div
                className={`relative my-2 px-6 py-3 font-bold group uppercase ${buttonColor === "primary" ? "text-white" : "text-[var(--primary-color)]"}`}
                onClick={onClick}
            >
                <span className={`absolute inset-0 w-full h-full transition duration-300 ease-out transform -translate-x-2 -translate-y-2 group-hover:translate-x-0 group-hover:translate-y-0 ${buttonColor === "primary" ? "bg-[var(--primary-color)]"
                    : buttonColor === "secondary" ? "bg-[var(--secondary-color)]"
                        : "bg-white"
                    }`}></span>
                <span className={`absolute inset-0 w-full h-full border-4 ${borderColor === "primary" ? "border-[var(--primary-color)]"
                    : borderColor === "secondary" ? "border-[var(--secondary-color)]"
                        : "border-white"
                    }`}></span>
                <span className="relative">
                    {buttonText}
                </span>
            </div>
        </>
    );
}

export default CustomButton;