import { 
  useEffect, 
  useState, 
  useRef 
} from "react";
import { useWindowScroll } from "react-use";
import Logo from "../../assets/logo.png";
import { useStateContext } from "../../context/ContextProvider";

import {
  BsHouseDoor,
  BsBasket,
  BsInfoCircle,
  BsHeadset
} from "react-icons/bs";
import { Link } from "react-router-dom";

const Nav = () => {
  const {
    t,
    i18n,
    langs,
    isLangRTL,
    getLangDetails
  } = useStateContext();

  let links = [
    {
      title: t("home"),
      links: [
        {
          name: t("home"),
          icon: <BsHouseDoor size={16} />,
          link: "/home",
        }
      ]
    },
    {
      title: t("products"),
      links: [
        {
          name: t("products"),
          icon: <BsBasket size={16} />,
          link: "/products",
        }
      ]
    },
    {
      title: t("about"),
      links: [
        {
          name: t("about"),
          icon: <BsInfoCircle size={16} />,
          link: "/about",
        }
      ]
    },
    // {
    //   title: t("contact"),
    //   links: [
    //     {
    //       name: t("contact"),
    //       icon: <BsHeadset size={16} />,
    //       link: "/contact",
    //     }
    //   ]
    // },
  ];

  // FIXED HEADER 
  const { y } = useWindowScroll();
  useEffect(() => {
    const header = document.querySelector('header');
    if (header) {
      const scrolled = y > 0;
      header.classList.toggle('bg-[var(--primary-color)]', scrolled);
      header.classList.toggle('bg-transparent', !scrolled);
    }
  }, [y]);

  // SMALL SCREEN NAV 
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef(null);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const closeNav = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsNavOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", closeNav);
    return () => {
      document.removeEventListener("click", closeNav);
    };
  }, []);

  // LANGUAGE MODAL
  const [langCode, setLangCode] = useState("");
  const [langTitle, setLangTitle] = useState("");
  const [openLangModal, setOpenLangModal] = useState(false);

  const openLang = () => {
    setOpenLangModal(true);
  };

  useEffect(() => {
    const langDetails = langs.find((lang) => lang?.code === i18n.language);
    if (langDetails) {
      const { code } = langDetails;
      setLangCode(code);
    }
  }, [i18n.language]);
  
  useEffect(() => {
    console.log("After setOpenLangModal(true):", openLangModal);
  }, [openLangModal]);

  useEffect(() => {
    const langDetails = getLangDetails(i18n.language);
    if (langDetails) {
      const { title, flag } = langDetails;
      setLangTitle(title);
      // setLangFlag(flag);
    }
  }, [getLangDetails]);

  const saveLang = async (code) => {
    setLangCode(code);
    setOpenLangModal(false);
  }

  const changeBodyDirection = (newDirection) => {
    document.body.style.direction = newDirection;
    localStorage.setItem("direction", newDirection);
  };

  const changeFontFamily = (fontFamily) => {
    document.body.style.fontFamily = fontFamily;
    localStorage.setItem("font", fontFamily);
  };

  return (
    <>
      {/* HEADER */}
      <header 
        className={`fixed top-0 left-0 w-full text-white transition-all duration-300 ${y > 100 ? "bg-[var(--primary-color)]" : "bg-transparent"}`}
        style={{
          zIndex: 5,
        }}
      >
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {/* LG SCREEN */}
          <nav className="flex items-center justify-between py-4">
            <div className="flex-shrink-0">
              <a href="#" title="" className="flex gap-2 items-center">
                <img className="w-auto h-12" src={Logo} alt="Lychee Gardens" />
                {/* <div className="text-xl text-white font-semibold">
                  {t("brand_title")}
                </div> */}
              </a>
            </div>
            <button 
              type="button" 
              onClick={(e) => { e.stopPropagation(); toggleNav(); }}
              className="inline-flex p-2 text-white transition-all duration-200 rounded-md lg:hidden focus:text-[var(--secondary-color)]"
            >
              {/* MENU OPEN: "hidden", MENU CLOSED: "block" */}
              <svg className="block w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8h16M4 16h16" />
              </svg>
              {/* MENU OPEN: "block", MENU CLOSED: "hidden" */}
              <svg className="hidden w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            {/* NAV LINKS */}
            <div className={`hidden lg:flex lg:items-center lg:gap-x-6 ${
              isLangRTL(i18n.language) ? "lg:mr-auto" : "lg:ml-auto"
            }`}>
              {links.map((section, index) => (
                section.links.map((link, linkIndex) => (
                  <Link
                    key={linkIndex}
                    to={link.link}
                    className={`inline-flex items-center gap-2 text-base font-medium transition-all duration-200 hover:text-[var(--secondary-color)] hover:border-b-2 hover:border-[var(--secondary-color)] ${
                      window.location.pathname === link.link ? "text-[var(--secondary-color)] border-b-2 border-[var(--secondary-color)]" : "text-white"
                    } ${
                      (!window.location.pathname) || (index === 0 && linkIndex === 0 && window.location.pathname === '/') ? "text-[var(--secondary-color)] border-b-2 border-[var(--secondary-color)]" : "text-white"
                    }`}
                  >
                    {link.icon}
                    {link.name}
                  </Link>
                ))
              ))}
            </div>
            {/* LANGUAGE BUTTON */}
            <div
              className={`lang-button mx-2 flex items-center hidden gap-2 lg:inline-flex p-2 rounded-md bg-[var(--secondary-color)] text-[var(--primary-color)] uppercase font-bold text-sm ${
                isLangRTL(i18n.language) ? "mr-10" : "ml-10"
              }`}
              style={{
                fontFamily: "Raleway",
              }}
              onClick={(e) => { openLang(); }}
            >
              {langCode}
              {/* <img
                className="rounded-sm h-6 w-8 border"
                src={langFlag}
                alt=""
              /> */}
            </div>
          </nav>

          {/* SCREEN SM TO LG  */}
          <nav 
            ref={navRef}
            className={`py-4 mb-4 bg-[var(--primary-color)] border border-[var(--secondary-color)] rounded-xl shadow-md lg:hidden ${
              isNavOpen ? "block" : "hidden"
            }`}
          >
            <div className="flow-root">
              <div className="flex flex-col px-6 -my-2 space-y-1">
                {links.map((section, index) => (
                  section.links.map((link, linkIndex) => (
                    <Link
                      key={linkIndex}
                      to={link.link}
                      className={`inline-flex items-center gap-3 py-2 text-base font-medium text-white transition-all duration-200 hover:text-[var(--secondary-color)] ${
                        window.location.pathname === link.link ? "text-[var(--secondary-color)] font-bold" : ""
                      } ${
                        (!window.location.pathname) || (index === 0 && linkIndex === 0 && window.location.pathname === '/') ? "text-[var(--secondary-color)] font-bold" : ""
                      }`}
                    >
                      {link.icon}
                      {link.name}
                    </Link>
                  ))
                ))}
              </div>
            </div>
            {/* LANGUAGE */}
            <div className="px-6 mt-6">
              <div
                className="lang-button mx-2 flex items-center justify-between gap-2 cursor-pointer p-2 px-3 rounded-md bg-[var(--secondary-color)] text-[var(--primary-color)] uppercase font-bold text-sm"
                onClick={(e) => { openLang(); }}
              >
                <div>{langCode}</div>
                <div>{langTitle}</div>
                {/* <img
                  className="rounded-sm h-6 w-8 border"
                  src={langFlag}
                  alt=""
                /> */}
              </div>
            </div>
          </nav>
        </div>
      </header>

      {openLangModal && (
        <div 
          className={`h-[100vh] w-[100vw] z-5 flex items-center justify-center`}
          style={{
            position: "fixed",
            top: "0",
            background: "rgb(0,0,0,0.5)",
            zIndex: "10",
          }}
        >
          <div 
            // ref={langRef}
            className="flex items-center justify-center p-5 bg-[var(--secondary-color)] rounded-xl text-black"
          >
            <div className="flex flex-col gap-3">
              {langs?.map((lang) => (
                <button
                  key={lang.code}
                  className={`cursor-pointer bg-[var(--primary-color)] text-white rounded-xl mb-1 py-2 px-3`}
                  onClick={(e) => {
                    localStorage.setItem("language", lang.code);
                    i18n.changeLanguage(lang.code);
                    saveLang(lang.code);

                    if (isLangRTL(lang.code)) {
                      changeBodyDirection("rtl");
                    } else {
                      changeBodyDirection("ltr");
                    }
                    changeFontFamily(lang.font);
                  }}
                >
                  <div className="grid grid-cols-2 gap-2">
                    <div 
                      className="text-start uppercase font-semibold"
                      style={{
                        fontFamily: "Raleway",
                      }}
                    >
                      {lang.code}
                      {/* <img
                        className="rounded-sm h-6 w-8 border border-[#AAAAAA]"
                        src={lang.flag}
                        alt=""
                      /> */}
                    </div>
                    <div
                      className="text-end"
                      style={{
                        fontFamily: lang?.font,
                      }}
                    >
                      {lang.title}
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
      </div>
      )}
    </>
  );
}

export default Nav;