import { useStateContext } from "../../context/ContextProvider";

import MainParallax from "../../components/elements/MainParallax";
import Hook from "../../components/elements/Hook";
import Contact from "../../components/contact/ContactComponent";

// IMAGES 
import BgImg from "../../assets/images/bg/main-bg-001.png";
import HookImage from "../../assets/images/site/lg-hook.jpg";
import AboutContent from "../../components/about/AboutContent";


const About = () => {
  const {
    t
  } = useStateContext();

  return (
    <>
      <MainParallax 
        title={t("about")} 
        subtitle={t("about_caption")}
        bg={BgImg} 
        minHeight="40vh"
        shade={true}
        shadeOpacity={"0.5"}
      />
      <div 
        className="relative bg-gray-100 text-[#333333] min-h-[100vh]"
        style={{
          zIndex: 4,
        }}
      >
        {/* MAIN CONTENT  */}
        <div className="p-4 sm:px-6 lg:px-8">
          <AboutContent heading={false} />
        </div>

        {/* HOOK  */}
        {/* <Hook
          hookImage={HookImage}
          hookContent={t("product_hook_content")}
        /> */}

        {/* CONTACT */}
        <Contact />
      </div>
    </>
  );
};

export default About;
