export const Products = (t) => [
    // FRESH FRUITS 
    {
        category: t("fresh_fruits"),
        product_name: t("egyptian_citrus"),
        product_desc: "More info",
        product_img: "./assets/products/citrus.png",
    },
    {
        category: t("fresh_fruits"),
        product_name: t("green_grapes"),
        product_desc: "More info",
        product_img: "./assets/products/green-grapes.png",
    },
    {
        category: t("fresh_fruits"),
        product_name: t("red_grapes"),
        product_desc: "More info",
        product_img: "./assets/products/red-grapes.png",
    },
    {
        category: t("fresh_fruits"),
        product_name: t("green_berries"),
        product_desc: "More info",
        product_img: "./assets/products/green-berries.png",
    },
    {
        category: t("fresh_fruits"),
        product_name: t("yellow_berries"),
        product_desc: "More info",
        product_img: "./assets/products/yellow-berries.png",
    },
    {
        category: t("fresh_fruits"),
        product_name: t("red_berries"),
        product_desc: "More info",
        product_img: "./assets/products/red-berries.png",
    },
    {
        category: t("fresh_fruits"),
        product_name: t("strawberry"),
        product_desc: "More info",
        product_img: "./assets/products/strawberry.png",
    },

    // FRESH VEGETABLES
    {
        category: t("fresh_vegetables"),
        product_name: t("red_onion"),
        product_desc: "More info",
        product_img: "./assets/products/red-onion.png",
    },
    {
        category: t("fresh_vegetables"),
        product_name: t("yellow_onion"),
        product_desc: "More info",
        product_img: "./assets/products/yellow-onion.png",
    },
    {
        category: t("fresh_vegetables"),
        product_name: t("white_onion"),
        product_desc: "More info",
        product_img: "./assets/products/white-onion.png",
    },
    {
        category: t("fresh_vegetables"),
        product_name: t("garlic"),
        product_desc: "More info",
        product_img: "./assets/products/garlic.png",
    },
    {
        category: t("fresh_vegetables"),
        product_name: t("okra"),
        product_desc: "More info",
        product_img: "./assets/products/okra.png",
    },
    {
        category: t("fresh_vegetables"),
        product_name: t("potato"),
        product_desc: "More info",
        product_img: "./assets/products/potato.png",
    },
    {
        category: t("fresh_vegetables"),
        product_name: t("tomato"),
        product_desc: "More info",
        product_img: "./assets/products/tomato.png",
    },

    // FROZEN FRUITS 
    {
        category: t("frozen_fruits"),
        product_name: t("strawberry"),
        product_desc: "More info",
        product_img: "./assets/products/strawberry.png",
    },
    {
        category: t("frozen_fruits"),
        product_name: t("banana"),
        product_desc: "More info",
        product_img: "./assets/products/banana.png",
    },
    {
        category: t("frozen_fruits"),
        product_name: t("guava"),
        product_desc: "More info",
        product_img: "./assets/products/guava.png",
    },
    {
        category: t("frozen_fruits"),
        product_name: t("mango"),
        product_desc: "More info",
        product_img: "./assets/products/mango.png",
    },
    {
        category: t("frozen_fruits"),
        product_name: t("pomegranate"),
        product_desc: "More info",
        product_img: "./assets/products/pomegranate.png",
    },
    {
        category: t("frozen_fruits"),
        product_name: t("fig"),
        product_desc: "More info",
        product_img: "./assets/products/fig.png",
    },

    // FROZEN VEGETABLES
    {
        category: t("frozen_vegetables"),
        product_name: t("carrot"),
        product_desc: "More info",
        product_img: "./assets/products/carrots.png",
    },
    {
        category: t("frozen_vegetables"),
        product_name: t("peas"),
        product_desc: "More info",
        product_img: "./assets/products/peas.png",
    },
    {
        category: t("frozen_vegetables"),
        product_name: t("molokhia"),
        product_desc: "More info",
        product_img: "./assets/products/molokhia.png",
    },
    {
        category: t("frozen_vegetables"),
        product_name: t("green_beans"),
        product_desc: "More info",
        product_img: "./assets/products/green-beans.png",
    },
    {
        category: t("frozen_vegetables"),
        product_name: t("mix_vegetables"),
        product_desc: "More info",
        product_img: "./assets/products/mix-vegetables.png",
    },

    // DRIED HERBS
    {
        category: t("dried_herbs"),
        product_name: t("chamomile_flower"),
        product_desc: "More info",
        product_img: "./assets/products/chamomile-flowers.png",
    },
    {
        category: t("dried_herbs"),
        product_name: t("hibiscus_flower"),
        product_desc: "More info",
        product_img: "./assets/products/hibiscus-flowers.png",
    },
    {
        category: t("dried_herbs"),
        product_name: t("calendula_flower"),
        product_desc: "More info",
        product_img: "./assets/products/calendula-flowers.png",
    },
    {
        category: t("dried_herbs"),
        product_name: t("marjoram"),
        product_desc: "More info",
        product_img: "./assets/products/marjoram.png",
    },
    {
        category: t("dried_herbs"),
        product_name: t("basil"),
        product_desc: "More info",
        product_img: "./assets/products/basil.png",
    },
    {
        category: t("dried_herbs"),
        product_name: t("spearmint"),
        product_desc: "More info",
        product_img: "./assets/products/spearmint.png",
    },
    {
        category: t("dried_herbs"),
        product_name: t("peppermint"),
        product_desc: "More info",
        product_img: "./assets/products/peppermint.png",
    },
    {
        category: t("dried_herbs"),
        product_name: t("lemon_grass"),
        product_desc: "More info",
        product_img: "./assets/products/lemon-grass.png",
    },

    // SPICES 
    {
        category: t("spices"),
        product_name: t("parsley"),
        product_desc: "More info",
        product_img: "./assets/products/parsley.png",
    },
    {
        category: t("spices"),
        product_name: t("dill"),
        product_desc: "More info",
        product_img: "./assets/products/dill.png",
    },
    {
        category: t("spices"),
        product_name: t("thyme"),
        product_desc: "More info",
        product_img: "./assets/products/thyme.png",
    },
    {
        category: t("spices"),
        product_name: t("rosemary"),
        product_desc: "More info",
        product_img: "./assets/products/rosemary.png",
    },
    {
        category: t("spices"),
        product_name: t("sage"),
        product_desc: "More info",
        product_img: "./assets/products/sage.png",
    },
    {
        category: t("spices"),
        product_name: t("oregano"),
        product_desc: "More info",
        product_img: "./assets/products/oregano.png",
    },
    {
        category: t("spices"),
        product_name: t("bay_leaves"),
        product_desc: "More info",
        product_img: "./assets/products/bay-leaves.png",
    },

    // SEEDS 
    {
        category: t("seeds"),
        product_name: t("caraway_seeds"),
        product_desc: "More info",
        product_img: "./assets/products/caraway-seeds.png",
    },
    {
        category: t("seeds"),
        product_name: t("fennel_seeds"),
        product_desc: "More info",
        product_img: "./assets/products/fennel-seeds.png",
    },
    {
        category: t("seeds"),
        product_name: t("anise_seeds"),
        product_desc: "More info",
        product_img: "./assets/products/anise-seeds.png",
    },
    {
        category: t("seeds"),
        product_name: t("fenugreek_seeds"),
        product_desc: "More info",
        product_img: "./assets/products/fenugreek-seeds.png",
    },
    {
        category: t("seeds"),
        product_name: t("cumin_seeds"),
        product_desc: "More info",
        product_img: "./assets/products/cumin-seeds.png",
    },
    {
        category: t("seeds"),
        product_name: t("black_cumin_seeds"),
        product_desc: "More info",
        product_img: "./assets/products/black-cumin-seeds.png",
    },
    {
        category: t("seeds"),
        product_name: t("white_sesame_seeds"),
        product_desc: "More info",
        product_img: "./assets/products/sesame-white-seeds.png",
    },
    {
        category: t("seeds"),
        product_name: t("basil_seeds"),
        product_desc: "More info",
        product_img: "./assets/products/basil-seeds.png",
    },
    {
        category: t("seeds"),
        product_name: t("coriander_seeds"),
        product_desc: "More info",
        product_img: "./assets/products/coriander-seeds.png",
    },
];