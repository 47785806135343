import { useRef, useState } from "react";
import { IoIosCall } from "react-icons/io";
import { MdOutlinePhoneIphone } from "react-icons/md";
import Button from "../button";
import ClipLoader from "react-spinners/ClipLoader";
import emailjs from "@emailjs/browser";
import { BsFillCheckCircleFill } from "react-icons/bs";
import SectionHeading from "../sectionHeading/SectionHeading";
import { useStateContext } from "../../context/ContextProvider";
import ContactImg from "../../assets/images/site/lp-c1.png";
import ContactBg from "../../assets/images/bg/contact-bg.png";

import {
  BsTelephone,
  BsEnvelopeAt,
  BsPinMap
} from "react-icons/bs";

const Contact = () => {
  const {
    t
  } = useStateContext();

  const handleFormSubmit = async () => {
    const name = document.getElementById('name').value;
    const contact = document.getElementById('contact').value;
    const message = document.getElementById('message').value;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('contact', contact);
    formData.append('message', message);

    try {
      const response = await fetch('../../controllers/sendEmail.php', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        console.log('Email sent successfully!');
        // Add your success handling logic here
      } else {
        console.error('Failed to send email');
        // Add your error handling logic here
      }
    } catch (error) {
      console.error('Error occurred:', error);
      // Add your error handling logic here
    }
  };
  
  return (
    <>
    <div 
      className="mt-10 py-10 p-4 lg:p-10 sm:px-6 lg:px-8 min-h-[90vh] flex items-center font-semibold"
      style={{
        backgroundImage: `url(${ContactBg})`,
        // backgroundImage: `linear-gradient(to bottom, rgba(168, 208, 125, 0.2), rgba(168, 208, 125, 0.5)), url(${ContactBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="w-full my-5 px-5">
        <div 
          className="glassmorphism-container p-10 text-white"
        >
          <div className="glassmorphism-primary"></div>
          <div className="relative">
            <div className="flex flex-col gap-2 text-center mb-5">
              <h1 className="text-2xl font-semibold">
                {t("contact_text")}
              </h1>
              <div className="text-sm font-semibold uppercase text-gray-300">
                {t("contact_caption")}
              </div>
            </div>
          {/* <div className="relative">
            <div className="flex flex-col gap-2 text-center mb-5">
              <h1 className="text-2xl font-semibold">
                {t("contact_text")}
              </h1>
              <div className="text-sm font-semibold uppercase text-gray-300">
                {t("contact_caption")}
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div>
                <p className="text-center leading-7 mb-5">
                  {t("contact_content")}
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-3 px-4 font-semibold text-white">
                  <div 
                    className="w-full grid grid-cols-8 items-center space-x-4 p-2 px-4 rounded-xl shadow-md"
                    style={{
                      background: "rgba(27, 71, 32, 0.7)",
                    }}
                  >
                    <BsTelephone size={24} />
                    <div 
                      className="col-span-7"
                      style={{
                        overflowWrap: "anywhere",
                      }}
                    >
                      +20 10 2545 2404
                    </div>
                  </div>
                  <div 
                    className="w-full grid grid-cols-8 items-center space-x-4 p-2 px-4 rounded-xl shadow-md"
                    style={{
                      background: "rgba(27, 71, 32, 0.7)",
                    }}
                  >
                    <BsEnvelopeAt size={24} />
                    <div 
                      className="col-span-7"
                      style={{
                        overflowWrap: "anywhere",
                      }}
                    >
                      info@lycheegardens.com
                    </div>
                  </div>
                  <div 
                    className="col-span-1 md:col-span-2 lg:col-span-1 w-full grid grid-cols-8 items-center space-x-4 p-2 px-4 rounded-xl shadow-md"
                    style={{
                      background: "rgba(27, 71, 32, 0.7)",
                    }}
                  >
                    <BsPinMap size={24} />
                    <div 
                      className="col-span-7"
                      style={{
                        overflowWrap: "anywhere",
                      }}
                    >
                      14 Melsa Compound, Golf Land, Heliopolis, Cairo, Egypt
                    </div>
                  </div>
                </div>
              </div>
              <div className="glassmorphism-container mt-4 lg:mt-0">
                <div className="glassmorphism-white"></div>
                <div className="relative contact-form p-8">
                  <div className="flex flex-col gap-4">
                  
                    <div className="flex flex-col gap-1">
                      <label className="uppercase">
                        {t("name")}
                      </label>
                      <input id="name" type="text" className="w-full" required />
                    </div>
                    
                    <div className="flex flex-col gap-1">
                      <label className="uppercase">
                        {t("contact_number")}
                      </label>
                      <input id="contact" type="text" className="w-full" required />
                    </div>
                    
                    <div className="flex flex-col gap-1">
                      <label className="uppercase">
                        {t("message")}
                      </label>
                      <input id="message" type="text" className="w-full" required />
                    </div>
                    
                    <button 
                    onClick={handleFormSubmit}
                      className="w-full uppercase p-2 rounded-xl shadow-sm"
                    >
                      {t("submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


          <p className="text-center leading-7 mb-5">
            {t("contact_content")}
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 font-semibold text-white">
            <div className="glassmorphism-container w-full p-4 lg:p-6">
              <div className="glassmorphism-white"></div>
              <div className="relative w-full h-full grid grid-cols-8 space-x-4 items-center">
                <BsTelephone size={24} />
                <div 
                  className="col-span-7"
                  style={{
                    overflowWrap: "anywhere",
                  }}
                >
                  <span>+20 102 545 2404</span>
                  <span className="mx-2">|</span>
                  <span>+20 100 777 4778</span>
                  <span className="mx-2">|</span>
                  <span>+20 120 458 0777</span>
                </div>
              </div>
            </div>
            <div className="glassmorphism-container w-full p-4 lg:p-6">
              <div className="glassmorphism-white"></div>
              <div className="relative w-full h-full grid grid-cols-8 space-x-4 items-center">
                <BsEnvelopeAt size={24} />
                <div 
                  className="col-span-7"
                  style={{
                    overflowWrap: "anywhere",
                  }}
                >
                  info@lycheegardens.com
                </div>
              </div>
            </div>
            <div className="glassmorphism-container col-span-1 md:col-span-2 lg:col-span-1 w-full p-4 lg:p-6">
              <div className="glassmorphism-white"></div>
              <div className="relative w-full h-full grid grid-cols-8 items-center space-x-4">
                <BsPinMap size={24} />
                <div 
                  className="col-span-7"
                  style={{
                    overflowWrap: "anywhere",
                  }}
                >
                  Arab Engineering Association, Farm No. 50A, Belbais Road, Obour City, Cairo, Egypt
                </div>
              </div>
            </div>
          </div>

          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Contact;
