import { useState } from "react";
import { useStateContext } from "../../context/ContextProvider";
import SectionHeading from "../sectionHeading/SectionHeading";
import ProductCard from "../elements/ProductCard";

// IMAGES 
import Product1 from "../../assets/images/site/lg-p1.png";
import Product2 from "../../assets/images/site/lg-p2.png";
import Product3 from "../../assets/images/site/lg-p3.png";

const ProductContent = ({
    heading
}) => {
    const {
        t
    } = useStateContext();

    return (
        <>
            <div className="w-full my-5 px-5">
                <div className="w-full h-full">
                    {heading && (
                        <SectionHeading
                            caption={t("product_caption")}
                            text={t("product_text")}
                        />
                    )}
                    <p className="font-light text-gray-600 leading-7">
                        {t("product_content")}
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-3">
                        <ProductCard
                            colorBool={true}
                            title={t("product1_title")}
                            content={t("product1_content")}
                            image={Product1}
                        />
                        <ProductCard
                            colorBool={true}
                            title={t("product2_title")}
                            content={t("product2_content")}
                            image={Product2}
                        />
                        <ProductCard
                            colorBool={true}
                            title={t("product3_title")}
                            content={t("product3_content")}
                            image={Product3}
                        />
                    </div>
                    <p className="font-light text-gray-600 leading-7">
                        {t("product_content_end")}
                    </p>
                </div>
            </div>
        </>
    );
};

export default ProductContent;
