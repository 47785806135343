import { useStateContext } from "../../context/ContextProvider";
import SectionHeading from "../sectionHeading/SectionHeading";
import Spinner300 from "../elements/Spinner300";
import FruitBowl from "../../assets/images/site/lg-001.png";

const Main = () => {
  const {
    t
  } = useStateContext();

  return (
    <div className="w-full my-5 px-5">
      <div className="flex flex-col lg:flex-row gap-4 items-center">
        <div className="w-full h-full">
          <SectionHeading 
            caption={t("brand_subtitle")} 
            text={t("brand_title")} 
            primary={false}
          />
          <p className="font-light text-gray-600 leading-7">
          {t("home_content")}
          </p>
        </div>
        <div className="flex items-center justify-center lg:justify-end">
          <div className="spin-anim-300">
            <Spinner300 />
            <img 
              src={FruitBowl}
              alt="Lychee Gardens"
              className="w-[200px] h-[200px] spin-center-elem"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
