import { useStateContext } from "../../context/ContextProvider";

const SectionHeading = ({
  caption, 
  text,
  primary
}) => {
  const {
    i18n,
    isLangRTL
  } = useStateContext();

  return (
    <div className="flex py-8 relative items-center">
      <div className="flex items-center gap-2">

        <div class="w-[10px] h-[50px]">
          <div class={`rounded-sm w-[10px] h-[10px] ${
            primary ? "square-primary" : "square-secondary"
          }`}></div>
        </div>

        <div className={`w-[3px] h-[60px] rounded-full ${
          primary ? "bg-[var(--primary-color)]" : "bg-[var(--secondary-color)]"
        }`}></div>
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl font-semibold text-[var(--secondary-color)]">
            {text}
          </h1>
          <div className="text-sm font-semibold uppercase text-[var(--primary-color)]">
            {caption}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHeading;