import { useStateContext } from "../../context/ContextProvider";

import MainParallax from "../../components/elements/MainParallax";
import Hook from "../../components/elements/Hook";
import Contact from "../../components/contact/ContactComponent";
import ProductContent from "../../components/products/ProductContent";

// IMAGES 
import BgImg from "../../assets/images/bg/lg-products-bg.jpeg";
import HookImage from "../../assets/images/site/lg-hook.jpg";
import ProductsList from "../../components/products/ProductsList";


const Products = () => {
  const {
    t
  } = useStateContext();

  return (
    <>
      <MainParallax 
        title={t("products")} 
        subtitle={t("product_caption")}
        bg={BgImg} 
        minHeight="40vh"
        shade={true}
        shadeOpacity={"0.5"}
      />
      <div 
        className="relative bg-gray-100 text-[#333333] min-h-[100vh]"
        style={{
          zIndex: 4,
        }}
      >
        {/* MAIN CONTENT  */}
        <div className="p-4 sm:px-6 lg:px-8">
          <ProductContent heading={false} />
        </div>

        {/* HOOK  */}
        {/* <Hook
          hookImage={HookImage}
          hookContent={t("product_hook_content")}
        /> */}

        {/* PRODUCTS CONTENT */}
        <ProductsList />


        {/* CONTACT */}
        <Contact />
      </div>
    </>
  );
};

export default Products;
