import React, { useState, useEffect } from 'react';
import { RiWhatsappFill } from 'react-icons/ri';
import { useStateContext } from "../../context/ContextProvider";

const WhatsAppButton = () => {
    const {
        i18n,
        isLangRTL
    } = useStateContext();

    const [isHovered, setIsHovered] = useState(false);

    const handleHover = () => {
        setIsHovered(true);
    };

    const handleLeave = () => {
        setIsHovered(false);
    };

    const openWhatsapp = () => {
        window.open('https://wa.me/201007774778', '_blank'); 
    };

    return (
        <div
            className={`rounded-full fixed shadow-md bottom-[75px] flex items-center bg-white p-1.5 ${
                isHovered ? "hovered" : ""
            } ${
                isLangRTL(i18n.language) ? "left-[7%]" : "right-[7%]"
            }`}
            style={{
                zIndex: 9,
            }}
            onMouseOver={handleHover}
            onMouseOut={handleLeave}
            onClick={openWhatsapp}
        >
            <RiWhatsappFill size={40} color={"#56c86e"} style={{ zIndex: 10 }} />
            {isHovered && (
                <div
                    className={`absolute text-[var(--primary-color)] bg-white font-bold p-2 px-4 rounded-lg bottom-[7%] ${
                        isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
                    }`}
                    style={{
                        right: !isLangRTL(i18n.language) && "calc(7% + 35px)",
                        left: isLangRTL(i18n.language) && "calc(7% + 35px)",
                    }}
                >
                    WhatsApp
                </div>
            )}
        </div>
    );
};

export default WhatsAppButton;
