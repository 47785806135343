import MainParallax from "../../components/elements/MainParallax";
import BgImg from "../../assets/images/bg/main-bg-002.png";
import Main from "../../components/home/Main";
import Hook from "../../components/elements/Hook";


import Contact from "../../components/contact/ContactComponent";
import { useStateContext } from "../../context/ContextProvider";
import ProductContent from "../../components/products/ProductContent";

import HookImage from "../../assets/images/site/lg-hook.jpg";
import AboutContent from "../../components/about/AboutContent";

const Home = () => {
  const {
    t
  } = useStateContext();

  return (
    <>
      <MainParallax 
        title={t("brand_title")}
        bg={BgImg} 
        minHeight="100vh"
        shade={true}
        shadeOpacity={"0.1"}
      />
      <div 
        className="relative bg-gray-100 text-[#333333] min-h-[100vh]"
        style={{
          zIndex: 4,
        }}
      >
        {/* MAIN  */}
        <div className="p-4 sm:px-6 lg:px-8">
          <Main />
        </div>
        
        {/* HOOK  */}
        <Hook
          hookImage={HookImage}
          hookContent={t("hook_content")}
        />

        {/* PRODUCTS */}
        <div className="p-4 sm:px-6 lg:px-8">
          <ProductContent heading={true} />
        </div>

        {/* ABOUT */}
        <div className="p-4 sm:px-6 lg:px-8">
          <AboutContent heading={true} />
        </div>

        {/* CONTACT */}
        <Contact />
      </div>
    </>
  );
};

export default Home;
