import { useState } from "react";
import SectionHeading from "../sectionHeading/SectionHeading";
import { useStateContext } from "../../context/ContextProvider";
import IconQuality from "../../assets/images/icons/quality.png";
import IconDistinction from "../../assets/images/icons/distinction.png";
import IconPartnership from "../../assets/images/icons/partnership.png";
import IconSustainability from "../../assets/images/icons/sustainability.png";
import IconReach from "../../assets/images/icons/reach.png";

const AboutContent = ({
  heading
}) => {
  const {
    t,
    i18n,
    isLangRTL
  } = useStateContext();

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const whyus = [
    {
      title: t("about1_title"),
      content: t("about1_content"),
      image: IconQuality,
      long_content: t("about1_long_content"),
    },
    {
      title: t("about2_title"),
      content: t("about2_content"),
      image: IconDistinction,
      long_content: t("about2_long_content"),
    },
    {
      title: t("about3_title"),
      content: t("about3_content"),
      image: IconPartnership,
      long_content: t("about3_long_content"),
    },
    {
      title: t("about4_title"),
      content: t("about4_content"),
      image: IconSustainability,
      long_content: t("about4_long_content"),
    },
    {
      title: t("about5_title"),
      content: t("about5_content"),
      image: IconReach,
      long_content: t("about5_long_content"),
    },
  ];

  return (
    <>
      <div className="w-full my-5 px-5">
        <div className="w-full h-full">
          {heading && (
            <SectionHeading
              caption={t("about_caption")}
              text={t("about_text")}
            />
          )}
          <p className="font-light text-gray-600 leading-7">
            {t("about_content")}
          </p>

          {heading ? (
            <div className="grid grid-cols-12 gap-4 h-full w-full my-5">
              <div className="col-span-12 lg:col-span-3 border-b-2 border-[#184720] lg:border-transparent p-4">
                {whyus.map((tab, index) => (
                  <div
                    key={index}
                    className={`w-full py-2 px-2 cursor-pointer font-semibold leading-7 rounded-sm ${isLangRTL(i18n.language) ? "border-r-2" : "border-l-2"
                      } ${activeTab === index
                        ? "active bg-[#184720] text-[#A8D07D] text-center border-transparent"
                        : "text-[var(--primary-color)] hover:text-[var(--secondary-color)] border-[var(--secondary-color)]"
                      }`}
                    onClick={() => handleTabClick(index)}
                  >
                    {tab?.title}
                  </div>
                ))}
              </div>
              <div className="col-span-12 lg:col-span-9 bg-[#A8D07D] rounded-xl shadow-sm p-4 flex items-center justify-center">
                {whyus.map((tab, index) => (
                  <div key={index} className={`flex flex-col justify-center items-center text-center p-4 ${activeTab === index ? "" : "hidden"
                    }`}>
                    {tab?.image && (
                      <img
                        src={tab?.image}
                        alt="Lychee Gardens"
                        className="w-[80px] h-[80px] mb-4"
                      />
                    )}
                    <p>
                      {tab?.content}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="w-full h-full my-5">
              {whyus.map((about, index) => (
                <div
                  key={index}
                  className="relative p-4"
                >
                  <div
                    className={`bg-white border-2 border-[var(--primary-color)] w-[100px] h-[100px] rounded-full shadow-sm flex items-center justify-center absolute
                  top-0 md:top-[50%]
                  translate-y-0 md:translate-x-0 md:translate-y-[-50%]  ${isLangRTL(i18n.language) ? "right-[50%] md:right-0 translate-x-[50%]" : "left-[50%] md:left-0 translate-x-[-50%]"
                      }`}
                    style={{
                      zIndex: 1,
                    }}
                  >
                    <img
                      src={about?.image}
                      alt="Lychee Gardens"
                      className="w-[70px] h-[70px]"
                    />
                  </div>
                  <div
                    className={`w-full h-full relative pt-[30px] md:pt-0 ${isLangRTL(i18n.language) ? "pr-0 md:pr-[30px]" : "pl-0 md:pl-[30px]"
                      }`}
                  >
                    <div
                      className={`bg-[var(--secondary-color)] flex items-center text-center md:text-start gap-4 p-4 rounded-xl shadow-md 
                        pt-[70px] md:pt-4 ${isLangRTL(i18n.language) ? "md:pr-[70px]" : "md:pl-[70px]"
                      }`}
                    >
                      <div className="flex flex-col gap-4">
                        <div className="font-semibold uppercase">
                          {about?.title}
                        </div>
                        <div>
                          {about?.long_content}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          
          <p className="font-light text-gray-600 leading-7">
            {t("about_content_end")}
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutContent;
