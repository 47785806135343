import { useState, useEffect } from "react";
import Products from "./products";
import Nav from "../components/navbar/Nav";
import { Route, Routes } from "react-router";
import Footer from "../components/footer/Footer";
import { useStateContext } from "../context/ContextProvider";
import Home from "./home";

import {
    RiWhatsappFill
} from "react-icons/ri";
import About from "./about";
import WhatsAppButton from "../components/elements/WhatsAppButton";
import ScrollToTopButton from "../components/elements/ScrollToTopButton";

const routes = [
    {
        path: "/home",
        element: <Home />,
        pageName: "Home",
    },
    {
        path: "/products",
        element: <Products />,
        pageName: "Products",
    },
    {
        path: "/about",
        element: <About />,
        pageName: "About",
    },
    // {
    //     path: "/contact",
    //     element: <Products />,
    //     pageName: "Contact",
    // },
];

export let load = null;

function App() {
    const {
        setAllRoutes,
        i18n
    } = useStateContext();

    useEffect(() => {
        setAllRoutes(routes);
    }, []);

    useEffect(() => {
        setAllRoutes(routes);
        i18n.changeLanguage(localStorage.getItem("language"));
        document.body.style.direction = localStorage.getItem("direction");
        document.body.style.fontFamily = localStorage.getItem("font");
        document.documentElement.style.setProperty("--font-family", localStorage.getItem("font"));
    }, []);

    return (
        <>
            <WhatsAppButton />
            <ScrollToTopButton />

            <div
                style={{
                    margin: 0,
                    padding: 0,
                }}
            >
                {/* ANIMATION  */}
                <div className="flex" id="body-div" style={{ width: "100vw" }}>
                    <div
                        className={`w-[100%] overflow-x-hidden`}
                    >
                        <div className={`px-0`}>
                            <Nav />
                        </div>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            {routes.map((route, index) => {
                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={route.element}
                                    />
                                );
                            })}
                        </Routes>
                    </div>
                    {/* <div 
                        className={`rounded-full shadow-md bottom-[7%] flex items-center bg-white p-1.5 ${
                            isLangRTL(i18n.language) ? "left-[7%]" : "right-[7%]"
                        } ${
                            isHovered ? "hovered" : ""
                        }`}
                        style={{
                            zIndex: 9,
                            position: "fixed",
                        }}
                        onMouseOver={handleHover}
                        onMouseOut={handleLeave}
                        onClick={openWhatsapp}
                    >
                        <RiWhatsappFill size={40} color={"#56c86e"} style={{ zIndex: 10 }} />
                        {isHovered && (
                            <div 
                                className={`absolute text-[#56c86e] bg-white font-bold p-2 px-4 rounded-lg bottom-[7%] ${
                                    isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
                                }`}
                                style={{
                                    left: isLangRTL(i18n.language) && "calc(7% + 35px)",
                                    right: !isLangRTL(i18n.language) && "calc(7% + 35px)",
                                }}
                            >
                                WHATSAPP
                            </div>
                        )}
                    </div> */}
                </div>
                <Footer />
            </div>
        </>
    );
}

export default App;
